import React from 'react';
import styled, { css } from 'styled-components';
import { library, config, dom } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faCaretRight } from '@fortawesome/pro-regular-svg-icons/faCaretRight';

import { getComponentSettings } from 'libs/content';
import StickyTableStyling, {
	generateFirstOfTypeStyle,
	generateLastOfTypeStyle,
} from 'libs/SharedStyling';
import Badge from 'components/products/Badge';
import { ComponentWrapper } from 'components/ComponentWrapper';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Text from 'parts/title-and-text/Text';
import Box from 'components/forms/Box';

// Enable fontAwesome pseudo elements
config.searchPseudoElements = true;

//#region Styling
const Wrap = styled.div`
	${p =>
		p.stickytable === 'true' &&
		css`
			${StickyTableStyling}
		`}

	.title {
		margin-bottom: ${p =>
			(p.headinglevel === 'h2' && p.theme.spacing.desktop.small) ||
			p.theme.spacing.desktop.xsmall};
		${p =>
			p.theme.media.mediumOnly(css`
				margin-bottom: ${p =>
					(p.headinglevel === 'h2' && p.theme.spacing.tablet.small) ||
					p.theme.spacing.tablet.xsmall};
			`)}
		${p =>
			p.theme.media.smallOnly(css`
				margin-bottom: ${p =>
					(p.headinglevel === 'h2' && p.theme.spacing.mobile.small) ||
					p.theme.spacing.mobile.xsmall};
			`)}
	}

	.text {
		margin-bottom: 0;

		${p => p.firstelement && generateFirstOfTypeStyle(p.firstelement)}
		${p => p.lastelement && generateLastOfTypeStyle(p.lastelement)}

		.component__related-content:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	.component__title-and-text .max-width,
	.component__related-content.max-width {
		padding: 0;
	}

	.component__related-content .spacing {
		padding-bottom: 0 !important;
	}
	.component__form .medium-width {
		padding: 0;
	}

	.spacing .table {
		margin-bottom: ${p => p.theme.spacing.desktop.small};
		${p =>
			p.theme.media.mediumOnly(css`
				margin-bottom: ${p => p.theme.spacing.tablet.small};
			`)}
		${p =>
			p.theme.media.smallOnly(css`
				margin-bottom: ${p => p.theme.spacing.mobile.small};
			`)}
	}

	/** Medium width intro on fullwidth */
	${p =>
		p.fullwidth &&
		css`
			.component__title-and-text .max-width .component__title-and-text {
				> .title,
				> .text > div:not(.text) {
					max-width: ${p => p.theme.widths.medium};
				}
				.component__stats > div {
					padding: 0;
				}
			}
		`}
`;

//#endregion

/**
 * BasicContent is a functional component that renders the basic content of a page.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.headinglevel - The heading level for the content (default is 'h2').
 * @param {boolean} props.lastblock - A flag indicating if this is the last block (default is false).
 * @param {Object} props.content - The main content to be displayed.
 * @param {Object} props.settings - The settings for the component.
 * @param {number} props.componentindex - The index of the component in its parent container.
 * @param {string} props.fullwidth - A flag indicating if the component should take up the full width of its container.
 * @param {Object} props.table - An object containing settings for a table, if one exists in the content.
 * @param {Object} props.children - The child elements of the component.
 * @param {string} props.stickTitle - The stick-title to be displayed above the main title.
 * @param {string} props.title - The main title of the content.
 * @param {string} props.intro - The introductory text for the content.
 * @param {Object} props.transitions - The transition settings for the component.
 *
 * @returns {JSX.Element} The JSX for the BasicContent component.
 */
export default function BasicContent({
	headinglevel = 'h2',
	lastblock = false,
	...props
}) {
	// Add fontAwesome icons to library and watch DOM
	library.add(faCheck, faXmark, faCaretRight);
	dom.watch();

	const { content, settings } = props;

	const componentSettings = getComponentSettings({
		settings,
		props,
		headinglevel,
		content,
	});

	// Set wrapper width based on settings
	let wrapperWidth =
		componentSettings?.fullwidth === 'true' ? 'large' : 'medium';

	// If the component has a table and it exists and has more than 5 columns, set wrapper width to 'large'
	if (
		componentSettings?.table?.exists === 'true' &&
		componentSettings?.table?.columns >= 5
	) {
		wrapperWidth = 'large';
	}

	// Neither text-contents or children is defined so return nothing
	if (!props?.content && !props?.children) return;

	return (
		<ComponentWrapper
			className="component__basic-content"
			width={wrapperWidth}
			spacing={props?.spacing}
			componentindex={props?.componentindex}
			{...componentSettings}>
			<Box
				shadow={componentSettings?.shadow}
				nopadding="true"
				maxPerRow={1}>
				<Wrap {...componentSettings} headinglevel={headinglevel}>
					{componentSettings?.badgenew === 'true' &&
						componentSettings?.hidetitle === 'true' && (
							<Badge
								variant="news"
								parentBg={componentSettings?.bg}
								transitions={props?.transitions}
							/>
						)}

					<TitleAndText
						{...props}
						text={props?.intro}
						wrapperWidth={wrapperWidth}
						headinglevel={headinglevel}
						settings={settings}
						nested={true}
						spacing={{
							bottom:
								(!!(props?.children || props?.content) &&
									'none') ||
								'xlarge',
						}}>
						{props?.children || (
							<>
								{props?.content && (
									<Text
										text={props?.content}
										componentSettings={componentSettings}
										wrapperWidth={wrapperWidth}
									/>
								)}
							</>
						)}
					</TitleAndText>
				</Wrap>
			</Box>
		</ComponentWrapper>
	);
}
